import axios from 'axios'

import {endpoint} from '../variables'


export const campaigns = () => {
  return axios
    .get(endpoint + '/api/campaigns')
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}

export const reports = (startDate, endDate, campaign) => {
  const apiParams = {
    start: startDate,
    end: endDate,
    campaign: campaign
  }
  return axios
    .get(endpoint + '/api/reports', { params: apiParams })
    .then(response => {
      return response
    })
    .catch(error => {
      throw error
    })
}