import React, {Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, Button, DropdownItem  } from 'reactstrap';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';

import {fetchCampaigns, fetchReports, selectCampaign} from '../../store/action'

class topbar extends Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.toggle1 = this.toggle1.bind(this);
        this.toggleprofile = this.toggleprofile.bind(this);
        this.togglebadge = this.togglebadge.bind(this);
        this.changeStart = this.changeStart.bind(this);
        this.changeEnd = this.changeEnd.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
          dropdownOpen: false,
          dropdownOpen1: false,
          dropdownOpenprofile: false,
          dropdownOpenbadge: false,
          startDate: new Date(),
          endDate: new Date(),
          default_date: new Date(),
          default:false, 
          data_attr:56, 
          postfix:20, 
          prefix:25,
          selectedCampaign: ''
        };
      }

      componentDidUpdate() {

      }

      changeStart(value, formattedValue) {
          this.setState({startDate: value});
      }

      changeEnd(value, formattedValue) {
          this.setState({endDate: value});
      }
      handleSubmit() {
          if (this.props.selectedCampaign === '') {
              alert('Select a campaign first');
              return false;
          }
          const startDateFormat = this.state.startDate.getFullYear() + '-' + (this.state.startDate.getMonth()+1) + '-' + this.state.startDate.getDate() + ' 00:00:00';
          const endDateFormat = this.state.endDate.getFullYear() + '-' + (this.state.endDate.getMonth()+1) + '-' + this.state.endDate.getDate() + ' 23:59:59';

          this.props.fetchReports(startDateFormat, endDateFormat, this.props.selectedCampaign);
      }
      handleChange = (selectedOption) => {
          this.props.selectCampaign(selectedOption.value)
          this.setState({selectedCampaign: selectedOption})
      }

      toggle() {
        this.setState(prevState => ({
          dropdownOpen: !prevState.dropdownOpen
        }));
      }
      toggle1() {
        this.setState(prevState => ({
          dropdownOpen1: !prevState.dropdownOpen1
        }));
      }
      toggleprofile() {
        this.setState(prevState => ({
          dropdownOpenprofile: !prevState.dropdownOpenprofile
        }));
      }
      togglebadge() {
        this.setState(prevState => ({
          dropdownOpenbadge: !prevState.dropdownOpenbadge
        }));
      }
      
    

    togglescreen(e)
    {
        if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    render() {
        const { selectedCampaign = '' } = this.state,
            displayCampaignSelect = this.props.selectedCampaign !== 'boberdoo_commissions' && this.props.selectedCampaign !== 'retreaver_commissions';
        if (console.log) console.log({selected: this.props.selectedCampaign, displayCampaignSelect})
        let options = [
            {value: 'loading', label: "Loading..."}
        ];
        if (this.props.campaigns.length > 0) {
            options = []
            this.props.campaigns.map((item) => {
                options.push({value: item.campaign_code, label: item.campaign_name});
            });
        }
        const customStyles = {
            container: () => ({
              // none of react-select's styles are passed to <Control />
              minWidth: 200,
            })
          }


        return (
            <div className="topbar">

            <div className="topbar-left">
                <Link to="/" className="logo">
                        <span>
                            <img src="//cege.com/wp-content/uploads/2018/11/cege-logo-white-1.png" alt="" height="30" />
                        </span>
                        <i>
                            <img src="//cege.com/wp-content/uploads/2018/11/cege-logo-white-1.png" alt="" height="15" />
                        </i>
                </Link>
            </div>

            <nav className="navbar-custom">
                <ul className="navbar-right d-flex list-inline float-right mb-0">
                    
                    <li className="dropdown notification-list d-none d-md-block">
                        <Link  onClick={() => this.togglescreen()} className="nav-link waves-effect" to="#" id="btn-fullscreen">
                            <i className="mdi mdi-fullscreen noti-icon"></i>
                        </Link>
                    </li>

                </ul>

                <ul className="list-inline menu-left mb-0">
                    <li className="float-left">
                        <button  className="button-menu-mobile open-left waves-effect">
                            <i className="mdi mdi-menu"></i>
                        </button>
                    </li>
                    <li className="float-left ml-md-3 d-flex align-items-center align-content-center">
                            <p className="py-4 m-0" style={{minWidth: '80px'}}>Start Date:</p>
                            <DatePicker
                                className="form-control"
                                id="datepicker-start"
                                selected={this.state.startDate}
                                onChange={this.changeStart}
                                /> 

                            <p className="py-4 m-0 ml-4" style={{minWidth: '70px'}}>End Date:</p>
                            <DatePicker
                                className="form-control"
                                id="datepicker-end"
                                selected={this.state.endDate}
                                onChange={this.changeEnd}
                                />
                            {displayCampaignSelect && (
                                <div>
                                    <Select
                                        styles={customStyles}
                                        className="ml-4"
                                        value={selectedCampaign}
                                        onChange={this.handleChange}
                                        options={options}
                                        />
                                </div>
                            )}
                            <Button outline color="primary" className="ml-4" onClick={this.handleSubmit}>Submit</Button>
                    </li>
                </ul>
            </nav>
        </div>
        );
    }
}

const mapStatetoProps = state =>{
    return {
       ...state.ui_red
    };
  }
export default withRouter(connect(mapStatetoProps,{ fetchCampaigns, fetchReports, selectCampaign })(topbar));