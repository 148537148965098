import { applyMiddleware, createStore, compose, combineReducers } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'

import  UIreducer from './reducers/reducer';
const rootReducer = combineReducers({
    ui_red:UIreducer,
});

// Chrometools Redux Extension enabled
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk, createLogger()))
)
