import axios from 'axios'
import * as vars from '../variables'

export const userLogin = (user, pass, remember) => {
  var postData = { email: user, password: pass, remember: remember }
  if (remember) {
    postData = {
      ...postData,
      remember: 1
    }
  }
  
  return axios
    .post(vars.endpoint + '/login', postData)
    .then(response => {
       return axios.post(vars.endpoint + '/oauth/token', {
            'client_id': '2',
            'client_secret': vars.clientKey,
            'grant_type': 'password',
            'username': user,
            'password': pass,
        }).then(res => {
            return res
        }).catch(e => {
            throw e
        })
    })
    .catch(error => {
      throw error
    })
}

export const userLogout = () => {
  return axios.get(vars.endpoint + '/logout')
}