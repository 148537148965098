const cookieToken = 'laravel_token'
const env = process.env.REACT_APP_ENV

let endpoint = 'https://api.cege.com/';
let clientKey = 'IftqUrQuXqMDpgRhetHfdgWidry2hek4IIa2dMHt';
switch (process.env.REACT_APP_ENV) {
  case 'dev':
    endpoint = 'http://localhost:3000/'
    clientKey = 'tfFX6JXVjJPkwueW4odHiZbhlBBWRyyK9yITtv0w'
    break
  case 'local':
    endpoint = 'http://api.reports.test/'
    clientKey = 'CqZKbCbZHT3R1JDyfhCXYejz5QhQzEdArhjwFOMT'
    break
  case 'production':
    endpoint = 'https://api.cege.com/'
    break
  default:
    endpoint = '/'
}

export { env, endpoint, cookieToken, clientKey }
