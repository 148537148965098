import moment from 'moment'

import * as actionTypes from '../action'

const initialState={
    topbar:true,
    sidebar:true,
    footer:true,
    loginpage:false,
    loggedIn: true,
    user: {},
    campaigns: {},
    reports: {},
    message: '',
    selectedCampaign: '',
    currentCampaign: '',
    reportsLoading: true
}

const reducer = (state=initialState,action) =>{
    switch(action.type){
        case actionTypes.TOPBAR:
            return{
                ...state,
                top_bar:!state.topbar
            };
        case actionTypes.SIDEBAR:
            return{
                ...state,
                sidebar:!state.sidebar
            };
        case actionTypes.FOOTER:
            return{
                ...state,
                footer:!state.footer
            };
        case actionTypes.LOGINPAGE:
            return{
                ...state,
                loginpage:!state.loginpage
            };
        case actionTypes.NONLOGINPAGE:
            return{
                ...state,
                loginpage:!state.loginpage
            };
        case actionTypes.USERLOGIN:
            return{
                ...state,
                loggedIn: true,
                user: action.payload,
            };
        case actionTypes.USERLOGINERROR:
            return{
                ...state,
                loggedIn: false,
                user: {},
                message: action.payload
            };
        case actionTypes.CAMPAIGNS:
            return{
                ...state,
                loggedIn: true,
                campaigns: action.payload
            };
        case actionTypes.CAMPAIGNSERROR:
            return{
                ...state,
                loggedIn: false,
                campaigns: initialState.campaigns,
                message: initialState.message
            };
        case actionTypes.CAMPAIGNSELECT:
            return{
                ...state,
                selectedCampaign: action.payload,
            };
        case actionTypes.CAMPAIGNCHANGED:
                return{
                    ...state,
                    currentCampaign: action.payload
                };
        case actionTypes.REPORTSLOADING:
            return{
                ...state,
                reports: {},
                reportsLoading: true
            };
        case actionTypes.REPORTS:
            return{
                ...state,
                loggedIn: true,
                reportsLoading: false,
                reports: action.payload
            };
        case actionTypes.REPORTSERROR:
            return{
                ...state,
                loggedIn: false,
                reports: initialState.reports,
                reportsLoading: false,
                message: initialState.message
            };
        default :
            return state;
    }
}

export default reducer;