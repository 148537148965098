import axios from 'axios';
import * as apiUser from '../api/user'
import * as apiReports from '../api/reports'
import * as vars from '../variables'

export const TOPBAR = 'topbar';
export const SIDEBAR = 'sidebar';
export const FOOTER = 'footer';
export const LOGINPAGE = 'loginpage';
export const NONLOGINPAGE = 'nonloginpage';
export const USERLOGIN = 'userlogin';
export const USERLOGINERROR = 'userloginerror';
export const CAMPAIGNS = 'campaigns';
export const CAMPAIGNSERROR = 'campaignserror';
export const CAMPAIGNSELECT = 'campaignselect';
export const CAMPAIGNCHANGED = 'campaignchanged'
export const REPORTSLOADING = 'reportsloading';
export const REPORTS = 'reports';
export const REPORTSERROR = 'reportserror';
export const USERLOGOUT = 'userlogout';

export const loginUser = (username, password, remember) => dispatch => {
    return apiUser
      .userLogin(username, password, remember)
      .then(response => {
        dispatch({
          type: USERLOGIN,
          payload: response.data
        })
        localStorage.setItem('cegeToken', JSON.stringify(response.data))
        axios.defaults.headers.authorization = 'Bearer ' + response.data.access_token
        return response.data
      })
      .catch(err => {
        dispatch({
          type: USERLOGINERROR,
          payload: err.response
        })
  
        throw err
      })
}

export const logoutUser = () => dispatch => {
    dispatch({
        type: USERLOGOUT,
        payload: 'loggedout'
    })
    localStorage.removeItem('cegeToken')
    apiUser.userLogout()
    window.location.href = '/login'
}

export const fetchCampaigns = () => dispatch => {
    return apiReports
      .campaigns()
      .then(response => {
        dispatch({
          type: CAMPAIGNS,
          payload: response.data.data
        })
        
        return response.data.data
      })
      .catch(err => {
        dispatch({
          type: CAMPAIGNSERROR,
          payload: err.response
        })
  
        throw err
      })
}

export const fetchReports = (startDate, endDate, campaign) => dispatch => {
    dispatch({type: CAMPAIGNSELECT, payload: campaign })
    dispatch({
        type: CAMPAIGNCHANGED,
        payload: campaign
    })
    dispatch({
        type: REPORTSLOADING,
        payload: true
    })
    return apiReports
      .reports(startDate, endDate, campaign)
      .then(response => {
        dispatch({
          type: REPORTS,
          payload: response.data.data
        })
        
        return response.data.data
      })
      .catch(err => {
        dispatch({
          type: REPORTSERROR,
          payload: err.response
        })
  
        throw err
      })
}

// export const fetchBoberdooReport = (startDate, endDate, campaign) => dispatch => {
//   dispatch({type: CAMPAIGNSELECT, payload: campaign })
//   dispatch({
//     type: CAMPAIGNCHANGED,
//     payload: campaign
//   })
//   return apiReports
//     .reports(startDate, endDate, campaign)
//     .then(response => {
//       dispatch({
//         type: REPORTS,
//         payload: response.data.data
//       })

//       dispatch({
//           type: REPORTSLOADING,
//           payload: true
//       })
      
//       return response.data.data
//     })
//     .catch(err => {
//       dispatch({
//         type: REPORTSERROR,
//         payload: err.response
//       })

//       throw err
//     })
// }

export const UpdateLogin = () => dispatch => { dispatch({type: LOGINPAGE, value: true })}
export const UpdateLoginAgain = () => dispatch => { dispatch({type: LOGINPAGE, value: false })}
export const GetOutOfLogin = () => dispatch => { dispatch({type: NONLOGINPAGE, value: true })}
export const selectCampaign = (campaign) => dispatch => { dispatch({type: CAMPAIGNSELECT, payload: campaign })}