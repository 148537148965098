import React, { Component } from 'react';
import { Route,Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as $ from 'jquery';
import Cookies from 'universal-cookie'
import axios from 'axios'

import Layout from './components/Layout/Layout';
import AUX from './hoc/Aux_';
import mainbuilder from './containers/mainbuilder/mainbuilder';
import { logoutUser, fetchCampaigns } from './store/action'

// Default Axios Header
const cookies = new Cookies()
const xsrfToken = cookies.get('XSRF-TOKEN')
if (typeof xsrfToken !== 'undefined') {
  axios.defaults.headers.common['X-XSRF-TOKEN'] = xsrfToken
}
const token = JSON.parse(localStorage.getItem('cegeToken'));
if (token && token.access_token) {
  axios.defaults.headers.authorization = 'Bearer ' + token.access_token
}

class App extends Component {
  state = {
    forceRedirect: true,
    redirect: false
  }

  componentDidUpdate(prevProps) {
    if (!this.props.loggedIn && prevProps.loggedIn && this.props.location.pathname !== '/login') {
      this.props.logoutUser()
    }
	}

  render() {
    let layout = null;

    layout = (
      <Layout topbar={this.props.topbar} footer = {this.props.footer} sidebar = {this.props.sidebar} loginpage={this.props.loginpage}>
        <Switch>  
          <Route path="/" component={mainbuilder} />
        </Switch>
      </Layout>);

    return (
      <AUX>
        {layout}
      </AUX>
    );
  }
}
const mapStatetoProps = state =>{
  return {
     ...state.ui_red
  };
}

export default withRouter(connect(mapStatetoProps,{ logoutUser, fetchCampaigns })(App));
