import React, {Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AUX from '../../hoc/Aux_';
// import { Scrollbars } from 'react-custom-scrollbars';
import $ from 'jquery';
import './Leftsidebar.css';

class sidebar extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            Tab: '', SubTab: '', MoreTab: '',
            email_menu: false, ui_menu: false, form_menu:false, chart_menu:false, 
            table_menu: false, icon_menu:false, map_menu:false, extra_menu:false, pages_menu:false, et_menu:false
        };
    }
    setActiveTab = (tab,subtab,moretab, e)=> {
        this.setState({Tab: tab,SubTab: subtab,MoreTab: moretab});
    }

    componentDidMount() {
        if ($(window).width() < 1025) {
            $('body').addClass('enlarged');
        } else {
            $('body').removeClass('enlarged');  
           
        }

        $('.button-menu-mobile').on('click', function (event) {
            event.preventDefault();
            $("body").toggleClass("enlarged");
            if($("body").hasClass("enlarged"))
            {
              $("#email_menu_hide").hide();
              $("#ui_menu_hide").hide();
              $("#form_menu_hide").hide();
              $("#chart_menu_hide").hide();
              $("#table_menu_hide").hide();
              $("#icon_menu_hide").hide();
              $("#map_menu_hide").hide();
              $("#page_menu_hide").hide();
              $("#extra_menu_hide").hide();
              $("#et_menu_hide").hide();
            }
            else
            {
                $("#email_menu_hide").show();
                $("#ui_menu_hide").show();
                $("#form_menu_hide").show();
                $("#chart_menu_hide").show();
                $("#table_menu_hide").show();
                $("#icon_menu_hide").show();
                $("#map_menu_hide").show();
                $("#page_menu_hide").show();
                $("#extra_menu_hide").show();
                $("#et_menu_hide").show();
            }
          
        });

      }

    render() {
        return (
            <AUX>
           
            <div className="left side-menu">
            {/* <Scrollbars  style={{ height: 1500}} > */}
                <div className="slimscroll-menu" id="remove-scroll">
                    <div id="sidebar-menu">
						
                        <ul className="metismenu" id="side-menu">
                            <li className="menu-title">Main</li>
                            <li>
                                <Link  to="index" className={this.state.Tab === 'index' ? 'waves-effect active':'waves-effect'} onClick={this.setActiveTab.bind(this, 'index','','')}>
                                    <i className="ti-home"></i><span> Dashboard </span>
                                </Link>
                            </li>
                            <li>
                                <Link  to="boberdoo-commission" className={this.state.Tab === 'boberdoo-commission' ? 'waves-effect active':'waves-effect'} onClick={this.setActiveTab.bind(this, 'boberdoo-commission','','')}>
                                    <i className="ti-home"></i><span> Boberdoo Commissions </span>
                                </Link>
                            </li>
                            <li>
                                <Link  to="retreaver-commission" className={this.state.Tab === 'retreaver-commission' ? 'waves-effect active':'waves-effect'} onClick={this.setActiveTab.bind(this, 'retreaver-commission','','')}>
                                    <i className="ti-home"></i><span> Retreaver Commissions </span>
                                </Link>
                            </li>

                            {/* <li>
                                <Link to="calendar"  className={this.state.Tab === 'calendar' ? 'waves-effect active':'waves-effect'}><i className="ti-calendar"></i><span> Calendar </span></Link>
                            </li>

                            <li>
                                <Link to="#" onClick={()=> this.setState({ email_menu: !this.state.email_menu }) } className={this.state.Tab === 'email' ? 'waves-effect active':'waves-effect'} ><i className="ti-email"></i><span> Email <span className="float-right menu-arrow"><i className="mdi mdi-chevron-right"></i></span> </span></Link>
                                {this.state.email_menu ?
                                    <ul className="submenu" id="email_menu_hide">
                                        <li className={this.state.SubTab === 'email-inbox' ? 'active':''}><Link  onClick={this.setActiveTab.bind(this, 'email','email-inbox','')} to="email-inbox">Inbox</Link></li>
                                        <li className={this.state.SubTab === 'email-read' ? 'active':''}><Link  onClick={this.setActiveTab.bind(this, 'email','email-read','')} to="email-read">Email Read</Link></li>
                                        <li className={this.state.SubTab === 'email-compose' ? 'active':''}><Link  onClick={this.setActiveTab.bind(this, 'email','email-compose','')} to="email-compose">Email Compose</Link></li>
                                    </ul>
                                    : null}
                            </li> */}
                        </ul>
                    </div>
                    <div className="clearfix"></div>
                </div>
             {/* </Scrollbars> */}
            </div>
            </AUX>
        );
    }
}

export default withRouter(sidebar);