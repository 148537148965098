import React , {Component } from 'react';
import { Sparklines,SparklinesLine  } from 'react-sparklines';
import { Scrollbars } from 'react-custom-scrollbars';
import { MDBDataTable } from 'mdbreact';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import AUX from '../../../hoc/Aux_';
import Settings from '../Subpages/Settings';
import Areacharts from '../Chartstypes/Areacharts';
import Piecharts from '../Chartstypes/Piecharts';
import {fetchReports, selectCampaign} from '../../../store/action'

class RetreaverCommission extends Component{
    constructor(props) {
        super(props);
        this.state = {
            columns: [
                {
                    label: 'Buyer Id',
                    field: 'buyer_id',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Buyer Name',
                    field: 'buyer_name',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Revenue',
                    field: 'revenue',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Data Cost',
                    field: 'cost',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Count',
                    field: 'total_count',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Total Call Duration',
                    field: 'time',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Agent Assigned',
                    field: 'assigned_agent_name',
                    sort: 'asc',
                    width: 150
                },
                // {
                //     label: 'Comm %',
                //     field: 'Profit Share',
                //     sort: 'asc',
                //     width: 150
                // }
            ],
            startDate: new Date(),
            endDate: new Date(),
            default_date: new Date(),
        }
    }
    componentDidMount() {
        //
        const startDateFormat = this.state.startDate.getFullYear() + '-' + (this.state.startDate.getMonth()+1) + '-' + this.state.startDate.getDate() + ' 00:00:00',
            endDateFormat = this.state.endDate.getFullYear() + '-' + (this.state.endDate.getMonth()+1) + '-' + this.state.endDate.getDate() + ' 23:59:59';
        this.props.selectCampaign('retreaver_commissions')
        this.props.fetchReports(startDateFormat, endDateFormat, 'retreaver_commissions');
    }

render(){
    const myChat = {
        width: '110%'
    };
    const {reports = {}, reportsLoading = true} = this.props;
    let campaignName = {
        campaign_code: 'retreaver_commissions',
        campaign_name: 'Retreaver Commissions'
    };
      
    return(
            <AUX>
                <div className="container-fluid">
                    <div className="page-title-box">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <h4 className="page-title">Retreaver Commissions</h4>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item active">Welcome to Cege Reporting</li>
                                </ol>
                            </div>
                            <div className="col-sm-6">
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-9">
                            <div className="card">
                                <div className="card-body">
                                    <h4 className="mt-0 header-title mb-5">Statistics{campaignName && campaignName.campaign_name && (<span> - {campaignName.campaign_name}</span>)}</h4>
                                    {reportsLoading && (
                                        <div className="spinner-border text-warning" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    )}
                                    {reports.length > 0 && (
                                        <MDBDataTable
                                            bordered
                                            hover
                                            data={{rows: reports, columns: this.state.columns}}
                                            order={['buyer_id', 'desc']}
                                            striped
                                            entries={25}
                                            entriesOptions={[25,50,100]}
                                            />
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3">
                        </div>
                    </div>

                </div>
            </AUX>
        );
    }
}

const mapStatetoProps = state =>{
    return {
       ...state.ui_red
    };
  }
export default withRouter(connect(mapStatetoProps,{ fetchReports, selectCampaign })(RetreaverCommission));